const PRICE_LIST_SEIKATSU_COMPENSATION = {
  ID: "seikatsu-compensation",
  PRICE_M: [
    { AGE: 15, 1: 160, 2: 0 },
    { AGE: 16, 1: 160, 2: 0 },
    { AGE: 17, 1: 160, 2: 0 },
    { AGE: 18, 1: 160, 2: 0 },
    { AGE: 19, 1: 160, 2: 0 },
    { AGE: 20, 1: 160, 2: 0 },
    { AGE: 21, 1: 160, 2: 0 },
    { AGE: 22, 1: 160, 2: 0 },
    { AGE: 23, 1: 160, 2: 0 },
    { AGE: 24, 1: 160, 2: 0 },
    { AGE: 25, 1: 160, 2: 0 },
    { AGE: 26, 1: 160, 2: 0 },
    { AGE: 27, 1: 160, 2: 0 },
    { AGE: 28, 1: 160, 2: 0 },
    { AGE: 29, 1: 160, 2: 0 },
    { AGE: 30, 1: 160, 2: 0 },
    { AGE: 31, 1: 160, 2: 0 },
    { AGE: 32, 1: 160, 2: 0 },
    { AGE: 33, 1: 160, 2: 0 },
    { AGE: 34, 1: 160, 2: 0 },
    { AGE: 35, 1: 160, 2: 0 },
    { AGE: 36, 1: 160, 2: 0 },
    { AGE: 37, 1: 160, 2: 0 },
    { AGE: 38, 1: 160, 2: 0 },
    { AGE: 39, 1: 160, 2: 0 },
    { AGE: 40, 1: 160, 2: 0 },
    { AGE: 41, 1: 160, 2: 0 },
    { AGE: 42, 1: 160, 2: 0 },
    { AGE: 43, 1: 160, 2: 0 },
    { AGE: 44, 1: 160, 2: 0 },
    { AGE: 45, 1: 160, 2: 0 },
    { AGE: 46, 1: 160, 2: 0 },
    { AGE: 47, 1: 160, 2: 0 },
    { AGE: 48, 1: 160, 2: 0 },
    { AGE: 49, 1: 160, 2: 0 },
    { AGE: 50, 1: 160, 2: 0 },
    { AGE: 51, 1: 160, 2: 0 },
    { AGE: 52, 1: 160, 2: 0 },
    { AGE: 53, 1: 160, 2: 0 },
    { AGE: 54, 1: 160, 2: 0 },
    { AGE: 55, 1: 160, 2: 0 },
    { AGE: 56, 1: 160, 2: 0 },
    { AGE: 57, 1: 160, 2: 0 },
    { AGE: 58, 1: 160, 2: 0 },
    { AGE: 59, 1: 160, 2: 0 },
    { AGE: 60, 1: 160, 2: 0 },
    { AGE: 61, 1: 160, 2: 0 },
    { AGE: 62, 1: 160, 2: 0 },
    { AGE: 63, 1: 160, 2: 0 },
    { AGE: 64, 1: 160, 2: 0 },
    { AGE: 65, 1: 160, 2: 0 },
    { AGE: 66, 1: 160, 2: 0 },
    { AGE: 67, 1: 160, 2: 0 },
    { AGE: 68, 1: 160, 2: 0 },
    { AGE: 69, 1: 160, 2: 0 },
    { AGE: 70, 1: 160, 2: 0 },
    { AGE: 71, 1: 160, 2: 0 },
    { AGE: 72, 1: 160, 2: 0 },
    { AGE: 73, 1: 160, 2: 0 },
    { AGE: 74, 1: 160, 2: 0 },
    { AGE: 75, 1: 160, 2: 0 },
  ],
  PRICE_F: [
    { AGE: 15, 1: 160, 2: 0 },
    { AGE: 16, 1: 160, 2: 0 },
    { AGE: 17, 1: 160, 2: 0 },
    { AGE: 18, 1: 160, 2: 0 },
    { AGE: 19, 1: 160, 2: 0 },
    { AGE: 20, 1: 160, 2: 0 },
    { AGE: 21, 1: 160, 2: 0 },
    { AGE: 22, 1: 160, 2: 0 },
    { AGE: 23, 1: 160, 2: 0 },
    { AGE: 24, 1: 160, 2: 0 },
    { AGE: 25, 1: 160, 2: 0 },
    { AGE: 26, 1: 160, 2: 0 },
    { AGE: 27, 1: 160, 2: 0 },
    { AGE: 28, 1: 160, 2: 0 },
    { AGE: 29, 1: 160, 2: 0 },
    { AGE: 30, 1: 160, 2: 0 },
    { AGE: 31, 1: 160, 2: 0 },
    { AGE: 32, 1: 160, 2: 0 },
    { AGE: 33, 1: 160, 2: 0 },
    { AGE: 34, 1: 160, 2: 0 },
    { AGE: 35, 1: 160, 2: 0 },
    { AGE: 36, 1: 160, 2: 0 },
    { AGE: 37, 1: 160, 2: 0 },
    { AGE: 38, 1: 160, 2: 0 },
    { AGE: 39, 1: 160, 2: 0 },
    { AGE: 40, 1: 160, 2: 0 },
    { AGE: 41, 1: 160, 2: 0 },
    { AGE: 42, 1: 160, 2: 0 },
    { AGE: 43, 1: 160, 2: 0 },
    { AGE: 44, 1: 160, 2: 0 },
    { AGE: 45, 1: 160, 2: 0 },
    { AGE: 46, 1: 160, 2: 0 },
    { AGE: 47, 1: 160, 2: 0 },
    { AGE: 48, 1: 160, 2: 0 },
    { AGE: 49, 1: 160, 2: 0 },
    { AGE: 50, 1: 160, 2: 0 },
    { AGE: 51, 1: 160, 2: 0 },
    { AGE: 52, 1: 160, 2: 0 },
    { AGE: 53, 1: 160, 2: 0 },
    { AGE: 54, 1: 160, 2: 0 },
    { AGE: 55, 1: 160, 2: 0 },
    { AGE: 56, 1: 160, 2: 0 },
    { AGE: 57, 1: 160, 2: 0 },
    { AGE: 58, 1: 160, 2: 0 },
    { AGE: 59, 1: 160, 2: 0 },
    { AGE: 60, 1: 160, 2: 0 },
    { AGE: 61, 1: 160, 2: 0 },
    { AGE: 62, 1: 160, 2: 0 },
    { AGE: 63, 1: 160, 2: 0 },
    { AGE: 64, 1: 160, 2: 0 },
    { AGE: 65, 1: 160, 2: 0 },
    { AGE: 66, 1: 160, 2: 0 },
    { AGE: 67, 1: 160, 2: 0 },
    { AGE: 68, 1: 160, 2: 0 },
    { AGE: 69, 1: 160, 2: 0 },
    { AGE: 70, 1: 160, 2: 0 },
    { AGE: 71, 1: 160, 2: 0 },
    { AGE: 72, 1: 160, 2: 0 },
    { AGE: 73, 1: 160, 2: 0 },
    { AGE: 74, 1: 160, 2: 0 },
    { AGE: 75, 1: 160, 2: 0 },
  ],
};
export { PRICE_LIST_SEIKATSU_COMPENSATION };

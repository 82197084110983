const PRICE_LIST_SEIMEI_COURSE = {
  ID: "seimei-course",
  PRICE_M: [
    {
      AGE: 15,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 16,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 17,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 18,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 19,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 20,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 21,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 22,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 23,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 24,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 25,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 26,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 27,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 28,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 29,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 30,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 31,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 32,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 33,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 34,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 35,
      14: 5930,
      13: 5337,
      12: 4744,
      11: 4151,
      10: 3558,
      9: 2965,
      8: 2372,
      7: 1779,
      6: 1186,
      5: 830,
      4: 593,
      3: 355,
      2: 237,
      1: 118,
    },
    {
      AGE: 36,
      14: 6470,
      13: 5823,
      12: 5176,
      11: 4529,
      10: 3882,
      9: 3235,
      8: 2588,
      7: 1941,
      6: 1294,
      5: 905,
      4: 647,
      3: 388,
      2: 258,
      1: 129,
    },
    {
      AGE: 37,
      14: 6470,
      13: 5823,
      12: 5176,
      11: 4529,
      10: 3882,
      9: 3235,
      8: 2588,
      7: 1941,
      6: 1294,
      5: 905,
      4: 647,
      3: 388,
      2: 258,
      1: 129,
    },
    {
      AGE: 38,
      14: 6470,
      13: 5823,
      12: 5176,
      11: 4529,
      10: 3882,
      9: 3235,
      8: 2588,
      7: 1941,
      6: 1294,
      5: 905,
      4: 647,
      3: 388,
      2: 258,
      1: 129,
    },
    {
      AGE: 39,
      14: 6470,
      13: 5823,
      12: 5176,
      11: 4529,
      10: 3882,
      9: 3235,
      8: 2588,
      7: 1941,
      6: 1294,
      5: 905,
      4: 647,
      3: 388,
      2: 258,
      1: 129,
    },
    {
      AGE: 40,
      14: 6470,
      13: 5823,
      12: 5176,
      11: 4529,
      10: 3882,
      9: 3235,
      8: 2588,
      7: 1941,
      6: 1294,
      5: 905,
      4: 647,
      3: 388,
      2: 258,
      1: 129,
    },
    {
      AGE: 41,
      14: 7370,
      13: 6633,
      12: 5896,
      11: 5159,
      10: 4422,
      9: 3685,
      8: 2948,
      7: 2211,
      6: 1474,
      5: 1031,
      4: 737,
      3: 442,
      2: 294,
      1: 147,
    },
    {
      AGE: 42,
      14: 7370,
      13: 6633,
      12: 5896,
      11: 5159,
      10: 4422,
      9: 3685,
      8: 2948,
      7: 2211,
      6: 1474,
      5: 1031,
      4: 737,
      3: 442,
      2: 294,
      1: 147,
    },
    {
      AGE: 43,
      14: 7370,
      13: 6633,
      12: 5896,
      11: 5159,
      10: 4422,
      9: 3685,
      8: 2948,
      7: 2211,
      6: 1474,
      5: 1031,
      4: 737,
      3: 442,
      2: 294,
      1: 147,
    },
    {
      AGE: 44,
      14: 7370,
      13: 6633,
      12: 5896,
      11: 5159,
      10: 4422,
      9: 3685,
      8: 2948,
      7: 2211,
      6: 1474,
      5: 1031,
      4: 737,
      3: 442,
      2: 294,
      1: 147,
    },
    {
      AGE: 45,
      14: 7370,
      13: 6633,
      12: 5896,
      11: 5159,
      10: 4422,
      9: 3685,
      8: 2948,
      7: 2211,
      6: 1474,
      5: 1031,
      4: 737,
      3: 442,
      2: 294,
      1: 147,
    },
    {
      AGE: 46,
      14: 8870,
      13: 7983,
      12: 7096,
      11: 6209,
      10: 5322,
      9: 4435,
      8: 3548,
      7: 2661,
      6: 1774,
      5: 1241,
      4: 887,
      3: 532,
      2: 354,
      1: 177,
    },
    {
      AGE: 47,
      14: 8870,
      13: 7983,
      12: 7096,
      11: 6209,
      10: 5322,
      9: 4435,
      8: 3548,
      7: 2661,
      6: 1774,
      5: 1241,
      4: 887,
      3: 532,
      2: 354,
      1: 177,
    },
    {
      AGE: 48,
      14: 8870,
      13: 7983,
      12: 7096,
      11: 6209,
      10: 5322,
      9: 4435,
      8: 3548,
      7: 2661,
      6: 1774,
      5: 1241,
      4: 887,
      3: 532,
      2: 354,
      1: 177,
    },
    {
      AGE: 49,
      14: 8870,
      13: 7983,
      12: 7096,
      11: 6209,
      10: 5322,
      9: 4435,
      8: 3548,
      7: 2661,
      6: 1774,
      5: 1241,
      4: 887,
      3: 532,
      2: 354,
      1: 177,
    },
    {
      AGE: 50,
      14: 8870,
      13: 7983,
      12: 7096,
      11: 6209,
      10: 5322,
      9: 4435,
      8: 3548,
      7: 2661,
      6: 1774,
      5: 1241,
      4: 887,
      3: 532,
      2: 354,
      1: 177,
    },
    {
      AGE: 51,
      14: 11120,
      13: 10008,
      12: 8896,
      11: 7784,
      10: 6672,
      9: 5560,
      8: 4448,
      7: 3336,
      6: 2224,
      5: 1556,
      4: 1112,
      3: 667,
      2: 444,
      1: 222,
    },
    {
      AGE: 52,
      14: 11120,
      13: 10008,
      12: 8896,
      11: 7784,
      10: 6672,
      9: 5560,
      8: 4448,
      7: 3336,
      6: 2224,
      5: 1556,
      4: 1112,
      3: 667,
      2: 444,
      1: 222,
    },
    {
      AGE: 53,
      14: 11120,
      13: 10008,
      12: 8896,
      11: 7784,
      10: 6672,
      9: 5560,
      8: 4448,
      7: 3336,
      6: 2224,
      5: 1556,
      4: 1112,
      3: 667,
      2: 444,
      1: 222,
    },
    {
      AGE: 54,
      14: 11120,
      13: 10008,
      12: 8896,
      11: 7784,
      10: 6672,
      9: 5560,
      8: 4448,
      7: 3336,
      6: 2224,
      5: 1556,
      4: 1112,
      3: 667,
      2: 444,
      1: 222,
    },
    {
      AGE: 55,
      14: 11120,
      13: 10008,
      12: 8896,
      11: 7784,
      10: 6672,
      9: 5560,
      8: 4448,
      7: 3336,
      6: 2224,
      5: 1556,
      4: 1112,
      3: 667,
      2: 444,
      1: 222,
    },
    {
      AGE: 56,
      14: 14300,
      13: 12870,
      12: 11440,
      11: 10010,
      10: 8580,
      9: 7150,
      8: 5720,
      7: 4290,
      6: 2860,
      5: 2002,
      4: 1430,
      3: 858,
      2: 572,
      1: 286,
    },
    {
      AGE: 57,
      14: 14300,
      13: 12870,
      12: 11440,
      11: 10010,
      10: 8580,
      9: 7150,
      8: 5720,
      7: 4290,
      6: 2860,
      5: 2002,
      4: 1430,
      3: 858,
      2: 572,
      1: 286,
    },
    {
      AGE: 58,
      14: 14300,
      13: 12870,
      12: 11440,
      11: 10010,
      10: 8580,
      9: 7150,
      8: 5720,
      7: 4290,
      6: 2860,
      5: 2002,
      4: 1430,
      3: 858,
      2: 572,
      1: 286,
    },
    {
      AGE: 59,
      14: 14300,
      13: 12870,
      12: 11440,
      11: 10010,
      10: 8580,
      9: 7150,
      8: 5720,
      7: 4290,
      6: 2860,
      5: 2002,
      4: 1430,
      3: 858,
      2: 572,
      1: 286,
    },
    {
      AGE: 60,
      14: 14300,
      13: 12870,
      12: 11440,
      11: 10010,
      10: 8580,
      9: 7150,
      8: 5720,
      7: 4290,
      6: 2860,
      5: 2002,
      4: 1430,
      3: 858,
      2: 572,
      1: 286,
    },
    {
      AGE: 61,
      14: 19820,
      13: 17838,
      12: 15856,
      11: 13874,
      10: 11892,
      9: 9910,
      8: 7928,
      7: 5946,
      6: 3964,
      5: 2774,
      4: 1982,
      3: 1189,
      2: 792,
      1: 396,
    },
    {
      AGE: 62,
      14: 19820,
      13: 17838,
      12: 15856,
      11: 13874,
      10: 11892,
      9: 9910,
      8: 7928,
      7: 5946,
      6: 3964,
      5: 2774,
      4: 1982,
      3: 1189,
      2: 792,
      1: 396,
    },
    {
      AGE: 63,
      14: 19820,
      13: 17838,
      12: 15856,
      11: 13874,
      10: 11892,
      9: 9910,
      8: 7928,
      7: 5946,
      6: 3964,
      5: 2774,
      4: 1982,
      3: 1189,
      2: 792,
      1: 396,
    },
    {
      AGE: 64,
      14: 19820,
      13: 17838,
      12: 15856,
      11: 13874,
      10: 11892,
      9: 9910,
      8: 7928,
      7: 5946,
      6: 3964,
      5: 2774,
      4: 1982,
      3: 1189,
      2: 792,
      1: 396,
    },
    {
      AGE: 65,
      14: 19820,
      13: 17838,
      12: 15856,
      11: 13874,
      10: 11892,
      9: 9910,
      8: 7928,
      7: 5946,
      6: 3964,
      5: 2774,
      4: 1982,
      3: 1189,
      2: 792,
      1: 396,
    },
    {
      AGE: 66,
      14: 27470,
      13: 24723,
      12: 21976,
      11: 19229,
      10: 16482,
      9: 13735,
      8: 10988,
      7: 8241,
      6: 5494,
      5: 3845,
      4: 2747,
      3: 1648,
      2: 1098,
      1: 549,
    },
    {
      AGE: 67,
      14: 27470,
      13: 24723,
      12: 21976,
      11: 19229,
      10: 16482,
      9: 13735,
      8: 10988,
      7: 8241,
      6: 5494,
      5: 3845,
      4: 2747,
      3: 1648,
      2: 1098,
      1: 549,
    },
    {
      AGE: 68,
      14: 27470,
      13: 24723,
      12: 21976,
      11: 19229,
      10: 16482,
      9: 13735,
      8: 10988,
      7: 8241,
      6: 5494,
      5: 3845,
      4: 2747,
      3: 1648,
      2: 1098,
      1: 549,
    },
    {
      AGE: 69,
      14: 27470,
      13: 24723,
      12: 21976,
      11: 19229,
      10: 16482,
      9: 13735,
      8: 10988,
      7: 8241,
      6: 5494,
      5: 3845,
      4: 2747,
      3: 1648,
      2: 1098,
      1: 549,
    },
    {
      AGE: 70,
      14: 27470,
      13: 24723,
      12: 21976,
      11: 19229,
      10: 16482,
      9: 13735,
      8: 10988,
      7: 8241,
      6: 5494,
      5: 3845,
      4: 2747,
      3: 1648,
      2: 1098,
      1: 549,
    },
    {
      AGE: 71,
      14: 34730,
      13: 31257,
      12: 27784,
      11: 24311,
      10: 20838,
      9: 17365,
      8: 13892,
      7: 10419,
      6: 6946,
      5: 4862,
      4: 3473,
      3: 2083,
      2: 1389,
      1: 694,
    },
    {
      AGE: 72,
      14: 38030,
      13: 34227,
      12: 30424,
      11: 26621,
      10: 22818,
      9: 19015,
      8: 15212,
      7: 11409,
      6: 7606,
      5: 5324,
      4: 3803,
      3: 2281,
      2: 1521,
      1: 760,
    },
    {
      AGE: 73,
      14: 41810,
      13: 37629,
      12: 33448,
      11: 29267,
      10: 25086,
      9: 20905,
      8: 16724,
      7: 12543,
      6: 8362,
      5: 5853,
      4: 4181,
      3: 2508,
      2: 1672,
      1: 836,
    },
    {
      AGE: 74,
      14: 46220,
      13: 41598,
      12: 36976,
      11: 32354,
      10: 27732,
      9: 23110,
      8: 18488,
      7: 13866,
      6: 9244,
      5: 6470,
      4: 4622,
      3: 2773,
      2: 1848,
      1: 924,
    },
    {
      AGE: 75,
      14: 51440,
      13: 46296,
      12: 41152,
      11: 36008,
      10: 30864,
      9: 25720,
      8: 20576,
      7: 15432,
      6: 10288,
      5: 7201,
      4: 5144,
      3: 3086,
      2: 2057,
      1: 1028,
    },
  ],
  PRICE_F: [
    {
      AGE: 15,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 16,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 17,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 18,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 19,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 20,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 21,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 22,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 23,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 24,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 25,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 26,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 27,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 28,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 29,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 30,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 31,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 32,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 33,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 34,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 35,
      14: 5210,
      13: 4689,
      12: 4168,
      11: 3647,
      10: 3126,
      9: 2605,
      8: 2084,
      7: 1563,
      6: 1042,
      5: 729,
      4: 521,
      3: 312,
      2: 208,
      1: 104,
    },
    {
      AGE: 36,
      14: 6080,
      13: 5472,
      12: 4864,
      11: 4256,
      10: 3648,
      9: 3040,
      8: 2432,
      7: 1824,
      6: 1216,
      5: 851,
      4: 608,
      3: 364,
      2: 243,
      1: 121,
    },
    {
      AGE: 37,
      14: 6080,
      13: 5472,
      12: 4864,
      11: 4256,
      10: 3648,
      9: 3040,
      8: 2432,
      7: 1824,
      6: 1216,
      5: 851,
      4: 608,
      3: 364,
      2: 243,
      1: 121,
    },
    {
      AGE: 38,
      14: 6080,
      13: 5472,
      12: 4864,
      11: 4256,
      10: 3648,
      9: 3040,
      8: 2432,
      7: 1824,
      6: 1216,
      5: 851,
      4: 608,
      3: 364,
      2: 243,
      1: 121,
    },
    {
      AGE: 39,
      14: 6080,
      13: 5472,
      12: 4864,
      11: 4256,
      10: 3648,
      9: 3040,
      8: 2432,
      7: 1824,
      6: 1216,
      5: 851,
      4: 608,
      3: 364,
      2: 243,
      1: 121,
    },
    {
      AGE: 40,
      14: 6080,
      13: 5472,
      12: 4864,
      11: 4256,
      10: 3648,
      9: 3040,
      8: 2432,
      7: 1824,
      6: 1216,
      5: 851,
      4: 608,
      3: 364,
      2: 243,
      1: 121,
    },
    {
      AGE: 41,
      14: 6560,
      13: 5904,
      12: 5248,
      11: 4592,
      10: 3936,
      9: 3280,
      8: 2624,
      7: 1968,
      6: 1312,
      5: 918,
      4: 656,
      3: 393,
      2: 262,
      1: 131,
    },
    {
      AGE: 42,
      14: 6560,
      13: 5904,
      12: 5248,
      11: 4592,
      10: 3936,
      9: 3280,
      8: 2624,
      7: 1968,
      6: 1312,
      5: 918,
      4: 656,
      3: 393,
      2: 262,
      1: 131,
    },
    {
      AGE: 43,
      14: 6560,
      13: 5904,
      12: 5248,
      11: 4592,
      10: 3936,
      9: 3280,
      8: 2624,
      7: 1968,
      6: 1312,
      5: 918,
      4: 656,
      3: 393,
      2: 262,
      1: 131,
    },
    {
      AGE: 44,
      14: 6560,
      13: 5904,
      12: 5248,
      11: 4592,
      10: 3936,
      9: 3280,
      8: 2624,
      7: 1968,
      6: 1312,
      5: 918,
      4: 656,
      3: 393,
      2: 262,
      1: 131,
    },
    {
      AGE: 45,
      14: 6560,
      13: 5904,
      12: 5248,
      11: 4592,
      10: 3936,
      9: 3280,
      8: 2624,
      7: 1968,
      6: 1312,
      5: 918,
      4: 656,
      3: 393,
      2: 262,
      1: 131,
    },
    {
      AGE: 46,
      14: 7640,
      13: 6876,
      12: 6112,
      11: 5348,
      10: 4584,
      9: 3820,
      8: 3056,
      7: 2292,
      6: 1528,
      5: 1069,
      4: 764,
      3: 458,
      2: 305,
      1: 152,
    },
    {
      AGE: 47,
      14: 7640,
      13: 6876,
      12: 6112,
      11: 5348,
      10: 4584,
      9: 3820,
      8: 3056,
      7: 2292,
      6: 1528,
      5: 1069,
      4: 764,
      3: 458,
      2: 305,
      1: 152,
    },
    {
      AGE: 48,
      14: 7640,
      13: 6876,
      12: 6112,
      11: 5348,
      10: 4584,
      9: 3820,
      8: 3056,
      7: 2292,
      6: 1528,
      5: 1069,
      4: 764,
      3: 458,
      2: 305,
      1: 152,
    },
    {
      AGE: 49,
      14: 7640,
      13: 6876,
      12: 6112,
      11: 5348,
      10: 4584,
      9: 3820,
      8: 3056,
      7: 2292,
      6: 1528,
      5: 1069,
      4: 764,
      3: 458,
      2: 305,
      1: 152,
    },
    {
      AGE: 50,
      14: 7640,
      13: 6876,
      12: 6112,
      11: 5348,
      10: 4584,
      9: 3820,
      8: 3056,
      7: 2292,
      6: 1528,
      5: 1069,
      4: 764,
      3: 458,
      2: 305,
      1: 152,
    },
    {
      AGE: 51,
      14: 8960,
      13: 8064,
      12: 7168,
      11: 6272,
      10: 5376,
      9: 4480,
      8: 3584,
      7: 2688,
      6: 1792,
      5: 1254,
      4: 896,
      3: 537,
      2: 358,
      1: 179,
    },
    {
      AGE: 52,
      14: 8960,
      13: 8064,
      12: 7168,
      11: 6272,
      10: 5376,
      9: 4480,
      8: 3584,
      7: 2688,
      6: 1792,
      5: 1254,
      4: 896,
      3: 537,
      2: 358,
      1: 179,
    },
    {
      AGE: 53,
      14: 8960,
      13: 8064,
      12: 7168,
      11: 6272,
      10: 5376,
      9: 4480,
      8: 3584,
      7: 2688,
      6: 1792,
      5: 1254,
      4: 896,
      3: 537,
      2: 358,
      1: 179,
    },
    {
      AGE: 54,
      14: 8960,
      13: 8064,
      12: 7168,
      11: 6272,
      10: 5376,
      9: 4480,
      8: 3584,
      7: 2688,
      6: 1792,
      5: 1254,
      4: 896,
      3: 537,
      2: 358,
      1: 179,
    },
    {
      AGE: 55,
      14: 8960,
      13: 8064,
      12: 7168,
      11: 6272,
      10: 5376,
      9: 4480,
      8: 3584,
      7: 2688,
      6: 1792,
      5: 1254,
      4: 896,
      3: 537,
      2: 358,
      1: 179,
    },
    {
      AGE: 56,
      14: 10310,
      13: 9279,
      12: 8248,
      11: 7217,
      10: 6186,
      9: 5155,
      8: 4124,
      7: 3093,
      6: 2062,
      5: 1443,
      4: 1031,
      3: 618,
      2: 412,
      1: 206,
    },
    {
      AGE: 57,
      14: 10310,
      13: 9279,
      12: 8248,
      11: 7217,
      10: 6186,
      9: 5155,
      8: 4124,
      7: 3093,
      6: 2062,
      5: 1443,
      4: 1031,
      3: 618,
      2: 412,
      1: 206,
    },
    {
      AGE: 58,
      14: 10310,
      13: 9279,
      12: 8248,
      11: 7217,
      10: 6186,
      9: 5155,
      8: 4124,
      7: 3093,
      6: 2062,
      5: 1443,
      4: 1031,
      3: 618,
      2: 412,
      1: 206,
    },
    {
      AGE: 59,
      14: 10310,
      13: 9279,
      12: 8248,
      11: 7217,
      10: 6186,
      9: 5155,
      8: 4124,
      7: 3093,
      6: 2062,
      5: 1443,
      4: 1031,
      3: 618,
      2: 412,
      1: 206,
    },
    {
      AGE: 60,
      14: 10310,
      13: 9279,
      12: 8248,
      11: 7217,
      10: 6186,
      9: 5155,
      8: 4124,
      7: 3093,
      6: 2062,
      5: 1443,
      4: 1031,
      3: 618,
      2: 412,
      1: 206,
    },
    {
      AGE: 61,
      14: 12380,
      13: 11142,
      12: 9904,
      11: 8666,
      10: 7428,
      9: 6190,
      8: 4952,
      7: 3714,
      6: 2476,
      5: 1733,
      4: 1238,
      3: 742,
      2: 495,
      1: 247,
    },
    {
      AGE: 62,
      14: 12380,
      13: 11142,
      12: 9904,
      11: 8666,
      10: 7428,
      9: 6190,
      8: 4952,
      7: 3714,
      6: 2476,
      5: 1733,
      4: 1238,
      3: 742,
      2: 495,
      1: 247,
    },
    {
      AGE: 63,
      14: 12380,
      13: 11142,
      12: 9904,
      11: 8666,
      10: 7428,
      9: 6190,
      8: 4952,
      7: 3714,
      6: 2476,
      5: 1733,
      4: 1238,
      3: 742,
      2: 495,
      1: 247,
    },
    {
      AGE: 64,
      14: 12380,
      13: 11142,
      12: 9904,
      11: 8666,
      10: 7428,
      9: 6190,
      8: 4952,
      7: 3714,
      6: 2476,
      5: 1733,
      4: 1238,
      3: 742,
      2: 495,
      1: 247,
    },
    {
      AGE: 65,
      14: 12380,
      13: 11142,
      12: 9904,
      11: 8666,
      10: 7428,
      9: 6190,
      8: 4952,
      7: 3714,
      6: 2476,
      5: 1733,
      4: 1238,
      3: 742,
      2: 495,
      1: 247,
    },
    {
      AGE: 66,
      14: 15320,
      13: 13788,
      12: 12256,
      11: 10724,
      10: 9192,
      9: 7660,
      8: 6128,
      7: 4596,
      6: 3064,
      5: 2144,
      4: 1532,
      3: 919,
      2: 612,
      1: 306,
    },
    {
      AGE: 67,
      14: 15320,
      13: 13788,
      12: 12256,
      11: 10724,
      10: 9192,
      9: 7660,
      8: 6128,
      7: 4596,
      6: 3064,
      5: 2144,
      4: 1532,
      3: 919,
      2: 612,
      1: 306,
    },
    {
      AGE: 68,
      14: 15320,
      13: 13788,
      12: 12256,
      11: 10724,
      10: 9192,
      9: 7660,
      8: 6128,
      7: 4596,
      6: 3064,
      5: 2144,
      4: 1532,
      3: 919,
      2: 612,
      1: 306,
    },
    {
      AGE: 69,
      14: 15320,
      13: 13788,
      12: 12256,
      11: 10724,
      10: 9192,
      9: 7660,
      8: 6128,
      7: 4596,
      6: 3064,
      5: 2144,
      4: 1532,
      3: 919,
      2: 612,
      1: 306,
    },
    {
      AGE: 70,
      14: 15320,
      13: 13788,
      12: 12256,
      11: 10724,
      10: 9192,
      9: 7660,
      8: 6128,
      7: 4596,
      6: 3064,
      5: 2144,
      4: 1532,
      3: 919,
      2: 612,
      1: 306,
    },
    {
      AGE: 71,
      14: 19040,
      13: 17136,
      12: 15232,
      11: 13328,
      10: 11424,
      9: 9520,
      8: 7616,
      7: 5712,
      6: 3808,
      5: 2665,
      4: 1904,
      3: 1142,
      2: 761,
      1: 380,
    },
    {
      AGE: 72,
      14: 20750,
      13: 18675,
      12: 16600,
      11: 14525,
      10: 12450,
      9: 10375,
      8: 8300,
      7: 6225,
      6: 4150,
      5: 2905,
      4: 2075,
      3: 1245,
      2: 830,
      1: 415,
    },
    {
      AGE: 73,
      14: 22760,
      13: 20484,
      12: 18208,
      11: 15932,
      10: 13656,
      9: 11380,
      8: 9104,
      7: 6828,
      6: 4552,
      5: 3186,
      4: 2276,
      3: 1365,
      2: 910,
      1: 455,
    },
    {
      AGE: 74,
      14: 25010,
      13: 22509,
      12: 20008,
      11: 17507,
      10: 15006,
      9: 12505,
      8: 10004,
      7: 7503,
      6: 5002,
      5: 3501,
      4: 2501,
      3: 1500,
      2: 1000,
      1: 500,
    },
    {
      AGE: 75,
      14: 27410,
      13: 24669,
      12: 21928,
      11: 19187,
      10: 16446,
      9: 13705,
      8: 10964,
      7: 8223,
      6: 5482,
      5: 3837,
      4: 2741,
      3: 1644,
      2: 1096,
      1: 548,
    },
  ],
  PRICE_C: [
    { AGE: 3, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 4, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 5, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 6, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 7, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 8, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 9, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 10, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 11, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 12, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 13, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 14, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 15, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 16, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 17, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 18, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 19, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 20, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 21, 1: 70, 2: 140, 3: 210, 4: 280 },
    { AGE: 22, 1: 70, 2: 140, 3: 210, 4: 280 },
  ],
};
export { PRICE_LIST_SEIMEI_COURSE };
